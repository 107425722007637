import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";
import ls from 'local-storage';
import _ from 'lodash';
import config from './config';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const cakereqid = urlParams.get('cake');

const pubid = urlParams.get('publisher_id');
console.log("publisher id " + pubid);

if (pubid == '1260') {
    var pixel_1260 = '<img src="https://www.facebook.com/tr?id=1062559747516093&ev=PageView&noscript=1" />';
    $('body').append(pixel_1260);
}
// CONFIGS
// var post_url = 'https://www.reallygreatrate.com/formdov.php';
// var post_url = '//' + config.endpoint + '/api/solar/';
var protocol = document.location.protocol;
var post_url = protocol + '//' + config.endpoint + '/formdov.php';

// If user leaves mid page
$(window).on('beforeunload', function() {
    const url = window.location.href;
    if(!url.toLowerCase().includes('terms-of-use.html') && !url.toLowerCase().includes('privacy-policy.html') && !url.toLowerCase().includes('partners-list.html')) {
        setTimeout(function () {
            setTimeout(function () {
                showPopUp();
                $('.popup')
                    .removeClass('hide');
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();
                var exit_strategy = true;
    
                getClickWall(exit_strategy);
                $('.step-form-wrap.data-step')
                    .removeClass('data-step');
            }, 1500);
        }, 1);
        return 'Would you like to see rates from our top lenders?';
    }
});


/*
   HELPERS
 */
/*
 * Basic email validation
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email)
        .match(pattern) ? true : false;
}
/*
 * Basic zipcode validation
 */
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}
/*
 * Sets hash value
 */
function setHash(hash) {
    // if(history.pushState) {
    //     history.pushState(null, null, hash);
    // }
    // else {
    //     location.hash = hash;
    // }
}
/*
 * Checks if value in array
 */
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

function RGRCallBack(EventID, Count)
{
    if (EventID == null) {
        EventID = "A1B2C3";
    }
    if (Count == null) {
        Count = 28;
    }


    $.ajax({
        async: false,
        
        url: "//www.reallygreatrate.com/api/mao_log/",
        type: "GET",
        dataType: 'json',
        data: { 
            'EventID': EventID,
            'Count': Count
        },
        
                
    });
}

var get_params = function (search_string) {
    var parse = function (params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1)
            .join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1)
        .split('&'));
};
/*
 USER IP
 */
$.ajax({
    url: '//www.reallygreatrate.com/api/user/ip_address/',
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip')
            .val(response.ip_address);
    }
});
/*
   MAGNIFIC POPUP
 */
$('.mfp-iframe')
    .each(function () { // the containers for all your galleries
        $(this)
            .magnificPopup({
                type: 'iframe',
                mainClass: 'iframe-popup'
            });
    });

$('.mfp-inline')
    .each(function () { // the containers for all your galleries
        $('.mfp-inline')
            .magnificPopup({
                type: 'inline'
            });
    });

/*
    POPUP
*/
function showPopUp() {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a')
            .first()
            .focus();
    }, 301);
    $('html')
        .addClass('popup-open');
    $('.popup')
        .fadeIn();
    $(window)
        .scrollTop(0);
}

function myAdOptimizerTracker() {
    var clickwallPercentage;
    var value = window.location.hostname;
    $.ajax({
        async: false,

        url: '//www.reallygreatrate.com/api/clickwall_percentage/',
        type: "GET",
        dataType: 'json',
        data: { 'website': value },
        success: function (response) {
            clickwallPercentage = response;
        }
                
    });

}
    
function create_UUIDLanding(){
   var dt = new Date().getTime();
   var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = (dt + Math.random()*16)%16 | 0;
       dt = Math.floor(dt/16);
       return (c=='x' ? r :(r&0x3|0x8)).toString(16);
   });
   return uuid;
}

var LandingID = 14;
var LandingEventID = create_UUIDLanding();
function getClickWall(exit_strategy = false) {
window.onerror = function (errorMsg, url, line) 
{
   
   //Add logging logic here
   $.ajax({
       async: false,
       
       url: "//www.reallygreatrate.com/api/mao_errorlog/",
       type: "POST",
       dataType: 'json',
       data: { 
           'errorMsg': errorMsg,
           'url': url,
           'line': line
       },
               
   });

   var MAOEndPointLanding = 'https://api.myadoptimizer.com/';
   var GlobalErrors = []; 
   var GlobalErrorRequest = {};
   GlobalErrorRequest.AdNetworkAPIID =  15;
   GlobalErrorRequest.ErrorStatusText = errorMsg + ' - ' + line;
   GlobalErrorRequest.ErrorTextStatus = 'Global Error ' + navigator.userAgent  + ' ' + window.location.origin + window.location.pathname; 
   GlobalErrorRequest.ErrorMessage = 'Global Error';
   GlobalErrorRequest.PayLoad = '';
   GlobalErrorRequest.APIURL  = url;
   GlobalErrorRequest.RequestDuration = 0;
   GlobalErrors.push(GlobalErrorRequest);


   var SaveURL  = MAOEndPointLanding + 'api/MAOSaveResults?';
   SaveURL += 'LandingPageID=' + LandingID;
   SaveURL += '&EventID=' + LandingEventID;
   SaveURL += '&RequestTypeID=' + 2;

   if (typeof jQuery == 'undefined') 
   {
       // Immediately-invoked function expression
       (function() {
           // Load the script
           var script = document.createElement("SCRIPT");
           script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js';
           script.type = 'text/javascript';
           script.onload = function() {
              $(document).ready(function(){
                  $.ajax({
                           url: SaveURL,
                           dataType: 'json',
                           type: 'post',
                           contentType: 'application/json',
                           timeout: 3000,
                           data: JSON.stringify(GlobalErrors)     
                   });
               });
           };
           document.getElementsByTagName("head")[0].appendChild(script);
       })();
    }
    else
    {
       $(document).ready(function(){
                   $.ajax({
                           url: SaveURL,
                           dataType: 'json',
                           type: 'post',
                           contentType: 'application/json',
                           timeout: 3000,
                           data: JSON.stringify(GlobalErrors)     
                   });
               });
    }

};

if(exit_strategy == true) {
    $('#confirmation_qs')
        .show();
    $('#confirmation_qs_text')
        .empty()
        .text("Click on a lender below for your free quote!");
    $('body').append("<script src='https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/32d7a62d-ff40-42d0-ab9f-764aa1ad40ee.js'><\/script>");
} else {
    $('#confirmation_qs')
        .show();
    $('body').append("<script src='https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/2f739c29-e650-4202-877a-f9624cb5bd9f.js'><\/script>");
    myAdOptimizerTracker();
}


    var maoZip = $('input[name="zip"]').val();
    var maoState = $('[name=state]').val();
    var maoCredit = $('[name="field_6"]:checked').val();
    var maoLoan = $('[name=field_4]').val();
    var maoHomeValue = $('[name=field_2]').val();
    var maoPropertyType = $('[name=field_1]:checked').val();
    var maoMilitary = $('[name="field_28"]:checked').val();
    var maoPub = $('[name=publisher_id]').val();
    var maoJornaya = $('[name=field_31]').val();
    var maoCashOut = $('input[name="cash_out"]:checked').val();
    var maoEmployed = $('[name=field_34]:checked').val();

    // MAO PII added  firstname lastname email phone address city 

    var maoFname = $('input[name="first_name"]').val();
    maoFname = encodeURIComponent(maoFname);
    var maoLname = $('input[name="last_name"]').val();
    maoLname = encodeURIComponent(maoLname);
    var maoEmail = $('input[name="email_address"]').val();
    var maoPhone = $('input[name="home_phone"]').val();
    maoPhone = encodeURIComponent(maoPhone);
    var maoCity = encodeURI(localStorage.getItem("city").replace(/['"]+/g, ''));
    var maoAddress = $('input[name="street_address"]').val();
    maoAddress = encodeURIComponent(maoAddress) + "," + encodeURIComponent(" " + localStorage.getItem("city").replace(/['"]+/g, '')) + "," + encodeURIComponent(" " + $('[name=state]').val() + " " + $('input[name="zip"]').val());

    
    if (maoPropertyType == 'Condominium') {
        maoPropertyType = 'Condo%2FTownhome';
    } else if (maoPropertyType == 'Multi-Family Dwelling Unit') {
        maoPropertyType = 'Multi-Unit';
    } else if (maoPropertyType == 'Mobile Home') {
        maoPropertyType = 'Mobile';
    }

    if (maoCashOut == 'Maybe') {
        maoCashOut = 'Yes';
    }

    if (maoMilitary == 'Y') {
        maoMilitary = 'Yes';
    }

    if (maoMilitary == 'N') {
        maoMilitary = 'No';
    }
    
    if (maoEmployed == "Other") {
        maoEmployed = "Not Employed";
    }

    if (exit_strategy == true) {
        var maoCashOut = '';
        var maoZip = '';
        var maoState = '';
        var maoCredit = '';
        var maoLoan = '';
        var maoHomeValue = '';
        var maoPropertyType = '';
        var maoMilitary = '';
        var maoEmployed = ''; 

        // MAO PII added 

        var maoFname = '';
        var maoLname = '';
        var maoEmail = '';
        var maoPhone = '';
        var maoAddress = '';
        var maoCity = '';

    }




    var maoParams = 
        "?zipcode=" + maoZip + 
        "&SourceID=" + maoPub +
        "&State=" + maoState +
        "&creditscore=" + maoCredit +
        "&loanbalance=" + maoLoan +
        "&propertyvalue=" + maoHomeValue +
        "&PropertyType=" + maoPropertyType +
        "&MilitaryStatus=" + maoMilitary +
        "&ExtClickID=" + maoJornaya +
        "&CashOut=" + maoCashOut +
        "&MediaChannel=" + "" + 
        "&EmploymentStatus=" + maoEmployed + 
        "&PropertyUse=" + "" +       
        "&FName=" + maoFname +
        "&LName=" + maoLname +
        "&Email=" + maoEmail +
        "&Phone=" + maoPhone + 
        "&Address=" + maoAddress +
        "&City=" + maoCity;

    window.history.replaceState(null, null, maoParams);

    var confirmation_div = $('#confirmation');

    confirmation_div.removeClass('collapse');
    confirmation_div.show();

}
function getOldClickWall(exit_strategy = false) {
    var confirmation_div = $('#confirmation');
    var affcid, key;


    // Generate Random Number to serve different click listings
    var randomized_num = getRandomNum(100); // random 1-100
    //console.log(randomized_num);
    // var renuantStates = ['ME', 'AK', 'MN', 'WA', 'UT', 'OR', 'MT', 'CT', 'MS', 'NH', 'NM'];
    if (exit_strategy == true) {
        var deleteCredit = "";
        $('[name=field_6]').val(deleteCredit);
    }
    var rt_data = {
        zip: $('input[name="zip"]')
            .val(),
        state: $('input[name="state"]')
            .val(),
        credit_status: $('input[name="field_6"]')
            .val(),
        loan_amt: $('input[name="field_3"]')
            .val(),
        military: $('input[name="field_28"]')
            .val(),
        property_value: $('input[name="field_2"]')
            .val(),
        property_type: $('input[name="field_1"]')
            .val(),
        publisher_id: $('input[name="publisher_id"]')
            .val(),
        subid: $('input[name="subid"]')
            .val()
    };

    var clickwallPercentage;

    $.ajax({
        async: false,
        url: "//www.reallygreatrate.com/api/clickwall_percentage/",
        type: "GET",
        dataType: 'json',
        success: function (response) {
            clickwallPercentage = response.number;
        }
                
    });
    
    //if number is below number or less serve clicks, otherwise serve renuant
    if (randomized_num <= clickwallPercentage) {
        /*
        CLICKS NET CLICK WALL
        */

        console.log('cn');
        //console.log($scope.data);

        if (exit_strategy == true) {
            affcid = "1103401";
            key = "FEg9_CYDZWw1";
            $('#confirmation_qs_text')
                .empty()
                .text("Click on a lender below for your free quote!");

        } else {
            affcid = "1058706";
            key = "56pg95fEw2k1";
        }
        var zip = $('input[name="zip"]')
            .val();

        //     Parameter for Credit Rating
        var clicksnet_credit_rating = 'Good (620 - 719)';
        //     Parameter for Loan Amount
        var clicksnet_loan_range = '$150,001 - $160,000';
        //     Parameter for Loan Type and Term
        var clicksnet_loan_type_term = 'Refinance - FHA Rate - 30 Year';
        //     Subids are used to track conversions and traffic
        var subid1 = $('input[name="publisher_id"]')
            .val();
        //     Subids are used to track conversions and traffic
        var subid2 = $('input[name="rcid"]')
            .val();

        //Optional preview parameter
        // var creative_id = clicksNetGetQueryStringParam('preview');

        //Optional Query Parameters:
        //     showHeader=[true||false] -> will show or hide header (Default: true)
        //     showWidget=[true||false] -> will show or hide the subWidget filter bar (Default: true)
        //     showFooter=[true||false] -> will show or hide footer (Default: true)
        var showHeader = false;
        var showWidget = false;
        var showFooter = false;

        //baseQuery 
        //Purpose: base script query
        var baseQuery = clicksNetGetProtocol() + 'cdn.fcmrktplace.com/listing/?affcamid=' +
            affcid + '&key=' + key +
            '&subid1=' + subid1 + '&subid2=' + subid2;

        //clicksnet_script_src 
        //Purpose: script to fetch listings 
        //Filter Query Parameters:
        //     clicksnet_credit_rating: (eg.) Excellent (720 or Above), Good (620 - 719), Fair (580 - 619), Poor (579 or Below)
        //     clicksnet_loan_range: loan amount (eg.) $70,001 - $80,000
        //     clicksnet_loan_type_term: Loan Type + Rate Type (eg.) Purchase - FHA Rate - 30 Year
        var clicksnet_script_src = baseQuery + '&zip=' + zip +
            '&clicksnet_credit_rating=' + clicksnet_credit_rating + '&clicksnet_loan_range=' + clicksnet_loan_range +
            '&clicksnet_loan_type_term=' + clicksnet_loan_type_term + '&showHeader=' + showHeader +
            '&showWidget=' + showWidget + '&showFooter=' + showFooter;

        var fetchListings = function () {
            var clicksnet_script_src_obj = {
                baseQuery: baseQuery,
                zip: zip,
                clicksnet_credit_rating: clicksnet_credit_rating,
                clicksnet_loan_range: clicksnet_loan_range,
                clicksnet_loan_type_term: clicksnet_loan_type_term
            };

            var clicksnet_callback = function () {
                (clicksnet_listing.innerHTML = stripAndExecuteScript(
                    clicksnet_listing_content.content
                )),
                'undefined' != typeof clicksnet_sub_widget &&
                    clicksnet_sub_widget.init_clicksnet_sw(clicksnet_script_src_obj),
                    clicksNetAddExpandButtonListeners();
            };

            var clicksnet_listing_count = clicksnet_listing_count || 0;
            clicksnet_listing_count += 1;
            var clicksnet_listing_id = 'clicksnet_listing_' + clicksnet_listing_count;
            document.getElementById('confirmation_clicksnet')
                .innerHTML = '<div id="' + clicksnet_listing_id + '" style="margin-top: 25px;"></div>';
            var clicksnet_script = document.createElement('script');
            (clicksnet_script.type = 'text/javascript'),
            (clicksnet_script.async = !0),
            (clicksnet_script.src = clicksnet_script_src + '&gh=true&ghs=true'),
            clicksnet_script.addEventListener ?
                clicksnet_script.addEventListener('load', clicksnet_callback, !1) :
                clicksnet_script.readyState &&
                (clicksnet_script.onreadystatechange = function () {
                    ('loaded' == clicksnet_script.readyState ||
                        'complete' == clicksnet_script.readyState) &&
                    clicksnet_callback();
                });
            var clicksnet_listing = document.getElementById(clicksnet_listing_id);
            clicksnet_listing.appendChild(clicksnet_script);
        };
        $('#confirmationRT').hide();
        $('#confirmation_qs')
            .show();
        fetchListings();
        // if the randomized number is 91-100 show the renuant click wall
    } else {
        // State in renuantStates
        /*
        RENUANT CLICK WALL
         */
        console.log('rcw');
        $('#scrollbox')
            .remove();

        var mapPropertyType = function (type) {
            switch (type) {
            case "Single Family":
                return "1";
            case "Multi-Family Dwelling Unit":
                return "3";
            case "Condominium":
                return "2";
            case "Mobile Home":
                return "4";
            }
        };

        var mapCreditStatus = function (credit) {
            switch (credit) {
            case "Excellent":
                return "1";
            case "Good":
                return "3";
            case "Fair":
                return "4";
            case "Poor":
                return "5";
            case "":
                return "";
            }

        };

        var mapMilitary = function (military) {
            var mapped = military == 'Y' ? 1 : 2;
            return mapped;
        };

        var mapLoanAmount = function (loan) {
            switch (loan) {
            case "59900":
            case "75000":
            case "90000":
                return "2";
            case "105000":
            case "120000":
            case "135000":
            case "150000":
            default:
                return "3";
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
                return "11";
            case "555000":
            case "570000":
            case "585000":
                return "12";
            case "600000":
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
                return "15";
            case "750000":
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var mapPropertyValue = function (value) {
            switch (value) {
            case "90000":
                return "2";
            case "105000":
            default:
                return "3";
            case "120000":
            case "135000":
            case "150000":
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
            case "555000":
                return "11";
            case "570000":
            case "585000":
            case "600000":
                return "12";
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
            case "750000":
                return "15";
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var renData = {
            state: rt_data.state,
            pubcampaignid: "5529",
            loantype: "refinance",
            propertytype: mapPropertyType(rt_data.property_type),
            PropertyUse: "1",
            creditscore: mapCreditStatus(rt_data.credit_status),
            militaryservice: mapMilitary(rt_data.military),
            foreclosure: "2",
            interestrate: "4.35",
            loanbalance: mapLoanAmount(rt_data.loan_amt),
            propertyvalue: mapPropertyValue(rt_data.property_value),
            mS1: "SLS",
            mS2: rt_data.publisher_id,
            mS3: rt_data.subid
        };
        // postscribe('#confirmation_sh', '<script src="https://api.renuant.com/search/api/Ads/Loader"></script>');
        // Renuant.Render(data);
        confirmation_div.removeClass('collapse');
        confirmation_div.show();
        var iframe = document.createElement("iframe"); // Create a <iframe> node
        iframe.setAttribute('id', 'renuant-conf');
        var rate_table_source = "https://api.renuant.com/search/blue/green/Render?state=" + renData.state + "&pubcampaignid=" + renData.pubcampaignid + "&loantype=" + renData.loantype + "&propertytype=" + renData.propertytype + "&PropertyUse=" + renData.PropertyUse + "&creditscore=" + renData.creditscore + "&militaryservice=" + renData.militaryservice + "&foreclosure=" + renData.foreclosure + "&interestrate=" + renData.interestrate + "&loanbalance=" + renData.loanbalance + "&propertyvalue=" + renData.propertyvalue + "&template_name=basic&mS1=" + renData.mS1 + "&mS2=" + renData.mS2 + "&mS3=" + renData.mS3;
        // console.log(rate_table_source);
        iframe.src = rate_table_source;
        iframe.style.cssText = "width:100%";
        iframe.frameborder = 0;
        iframe.scrolling = "no";
        document.getElementById("confirmationRT")
            .appendChild(iframe);
    }
}

function getRandomNum(num) {
    return Math.floor(Math.random() * Math.floor(num)) + 1;
}


function hidePopUp() {
    $('html')
        .removeClass('popup-open');
    $('.popup')
        .fadeOut();
}
/*
    Generate a TrustedForm Certificate
 */
$('input')
    .on('input', function () {
        this.setAttribute("value", $(this)
            .val());
    });

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript';
tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date()
    .getTime() + Math.random();
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(tf, s);

/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if (typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]')
        .val(params.publisher_id);
} else {
    $('input[name="publisher_id"]')
        .val(632);
}
// subid
if (typeof params.subid !== "undefined") {
    $('input[name="subid"]')
        .val(params.subid);
} else {
    $('input[name="subid"]')
        .val('');
}
// rcid
if (typeof params.rcid !== "undefined") {
    $('input[name="rcid"]')
        .val(params.rcid);
} else {
    $('input[name="rcid"]')
        .val('');
}

/*
    HID
 */
$.ajax({
    url: '//' + config.endpoint + '/api/hdi/?upload_type=' + $('input[name="upload_type"]')
        .val() + '&publisher_id=' + $('input[name="publisher_id"]')
        .val(),
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]')
            .val(response.hid);
    }
});



/*
    ZIP CODE
 */
$('.start-form button')
    .click(function (e) {
        e.preventDefault();
        var $el = $(this)
            .prev('input');
        var $button = $(this);
        var value = $el.val();
        var $form = $el.parent('form');
        // $form.find('.error')
        //     .fadeOut();
        $el.removeClass('input-error');

        if (!$button.is(":disabled")) {
            $button.prop('disabled', true);
            if (value === "" || !isValidZipCode(value)) {
                $el.addClass('input-error');
                $form.find('.error')
                    .text('Please enter a valid ZIP code')
                    .fadeIn();
            }
            // if (value >= 6001 && value <= 6228) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Connecticut consumers')
            //         .fadeIn();
            // } else {
            //     getCity(value);
            // }
            getCity(value);
            $button.prop('disabled', false);
        }
    });

/*
 * Get city name
 */
var zip_response = null;

function getCity(value) {
    // Check zip code
    $.ajax({
        url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
        dataType: 'json',
        method: 'get',
        data: { 'zip': value },
        success: function (response) {
            zip_response = response;
            zip_response.zip = value;

            $('input[name="zip"]')
                .val(value);
            $('input[name="city"]')
                .val(response.city);
            $('input[name="state"]')
                .val(response.state);

            $('.street-info')
                .text(response.city + ', ' + response.state + ', ' + value);
            $('.city-title-popup')
                .text(response.city);

            ls('zip', value);
            ls('city', response.city);
            ls('state', response.state);

            if (response.city) {
                showPopUp();
            } else {
                $('.error').fadeIn();
                // $el.addClass('input-error');
                // $form.find('.error')
                    

            }
        }
    });
}

$(document)
    .on('change', '.radio-list:visible input', function () {
        $('.radio-list:visible li')
            .removeClass('active');
        $(this)
            .parent('label')
            .parent('li')
            .addClass('active');
    });

var step_2 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_1"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please Select Home Type</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_3 = {
    select: function () {
        // Init custom selects
        $('#property-value')
            .selectric({
                nativeOnMobile: true,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();
                    //if(el_text !== 'Other') {
                    //    $('input[name="provider-radio"]').prop('checked', false);
                    //   $('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                    //}
                }
            });
        //$(document).on('change', '#property-value', function () {
        //var val = $('#property-value option:selected').val();
        //$('input[name="property-value"]').prop('checked', false);
        //$('input[name="field_2"]').val(val);
        //});

        $('#mortgage-balance')
            .selectric({
                nativeOnMobile: true,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();
                    //if(el_text !== 'Other') {
                    //$('input[name="mortgage-balance"]').prop('checked', false);
                    //$('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                    //}
                }
            });
        $(document)
            .on('change', 'mortgage-balance', function () {
                //var val = $('#property-value option:selected').val();
                //$('input[name="mortgage-balance"]').prop('checked', false);
                //$('input[name="field_2"]').val(val);
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_prop_selected = ($('#property-value option:selected')
                .length > 0);
            var is_mort_selected = ($('#mortgage-balance option:selected')
                .length > 0);
            if (!is_prop_selected && !is_mort_selected) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select estimated property value and estimated mortgage balance</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (!is_prop_selected) {
                    $('.step-form .step.active .errors')
                        .html('<span class="error">Please select estimated property value</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (!is_mort_selected) {
                    $('.step-form .step.active .errors')
                        .html('<span class="error">Please select estimated mortgage balance</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
            }
            $button.prop('disabled', false);
        }
    }
};

step_3.select();

var step_4 = {
    validate: function () {

        var $button = $('.step-form .step.active button');

        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            if (!$('.step-form .step.active input[name="field_6"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select how you would rate your credit</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }

            $button.prop('disabled', false);
        }
    }
};

var step_5 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="cash_out"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if you would like to take cash out</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_6 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_34"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select your employment status</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_7 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_23"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if your mortgage is FHA</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_8 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_28"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if you are a veteran or active military</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

$(document).on('keyup', 'input[name=first_name], input[name=last_name]', function(e) {
	if (!e.target.value.match(/^[A-Za-z]+$/)) {
		let val = e.target.value.match(/[A-Za-z]+/g);
		if (val) {
			$(this).val(val.join(''));
		} else {
			$(this).val('');
		}
				
	}
});

var step_9 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]')
                .val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]')
                .val();
            last_name = $.trim(last_name);
            // Email
            var email = $('.step-form .step.active input[name="email_address"]')
                .val();
            email = $.trim(email);

            if (first_name === "" && last_name === "" && email === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your First Name, Last Name and Email</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (email === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                } else {
                    if (!validEmail(email)) {
                        $('.step-form .step.active .errors')
                            .append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active')
                            .addClass('has-errors');
                    }
                }
            }
            $button.prop('disabled', false);
        }
    }
};

var step_10 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // Phone
            var phone = $('.step-form .step.active input[name="home_phone"]')
                .val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');
            // Address
            var street = $('.step-form .step.active input[name="street_address"]')
                .val();
            street = $.trim(street);

            if (phone === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Phone Number</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else if (phone !== "" && phone.length < 10) {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }

            if (phone.startsWith("1") == true) {
              $('.step-form .step.active .errors')
                  .append('<span class="error">Please don\'t start Phone Number with 1</span>');
              $('.step-form .step.active')
                  .addClass('has-errors');
            }

            if (phone.startsWith("0") == true) {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please don\'t start Phone Number with 0</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
              }
            
            if (street === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Street Address</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {

    setHash('#' + step);
    $('.step-form .step')
        .removeClass('active');
    $('.step-form .step[data-step="' + step + '"]')
        .addClass('active');
    $('.form-top .steps .current')
        .text(step);
    //$('.step-form .step.active input').first().focus();
    switch (step) {
    case 3:
        //$('input[name="field_2"]').val($('input[name="provider-radio"]:checked').val());
        break;
    case 4:
        //set Loan Amount value to first mortgage balance value
        var f4value = $("[name = field_4]")
            .val();
        $("[name = field_3]")
            .val(f4value);
        break;
    case 6:
        // Add input mask
        var selector = document.getElementById("home_phone");
        var im = new Inputmask("9999999999");
        im.mask(selector);
        break;
    }
}

$(document)
    .on('click', '.step-back', function (e) {
        e.preventDefault();
        var step = $('.step-form .step.active')
            .data('step') - 1;
        if (step > 1) {
            changeStep(step);
        } else {
            // hide popup
            hidePopUp();
        }
    });

$(document)
    .on('submit', 'form[name="leadform"]', function (e) {
        e.preventDefault();
    });

$(document)
    .on('click', '.step-button:visible', function (e) {
        e.preventDefault();
        var step = $(this)
            .data('step');
        if (step === 'submit') {
            var anura_json = sessionStorage.getItem('anura_final_response');

            step_10.validate();
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                convert_fire = true;
                window._conv_q = window._conv_q = [];
                _conv_q.push(['recheck_goals']);

                //submit
                $('[name=anura]')
                    .val(anura_json);
                $('[name=referrer]')
                    .val(document.referrer);
                $('[name=useragent]')
                    .val(window.navigator.userAgent);
                var formData = $('#leadform')
                    .serialize();
                // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                // if(iOS == false) {
                //     if (typeof _webpushTrackAttributes == 'function') { 
                //       console.log("call push pros: true");

                //       _webpushTrackAttributes({
                //         "aff_id": $('[name=publisher_id]').val(),
                //         "military": $('[name=field_28]').val(),
                //         "first_name": $('[name=first_name]').val(),
                //         "last_name": $('[name=last_name]').val(),
                //         "state": $('[name=state]').val(),
                //         "zip_code": $('[name=zip_code]').val(),
                //         "home_type": $('[name=field_1]').val(),
                //         "property_value": $('[name=field_2]').val(),
                //         "mortgage_balance": $('[name=field_4]').val(),
                //         "cash_out": $('[name=cash_out]').val(),
                //         "credit_status": $('[name=field_6]').val()
                //     });
                //     } else {
                //         console.log("call push pros: false");
                //     }
                // }
                
                // Ajax call to post data
                $.ajax({
                    url: post_url,
                    dataType: 'json',
                    method: 'post',
                    data: formData,
                    success: function (results) {
                        console.log(results);
                        
                        if (results.status == 'success') {

                            if ($('input[name="publisher_id"]').val() == '366') {
                                var ltv_366 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_366 = $('select[name="field_4"]').val();
                                var credit_366 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_366 = ['Fair', 'Excellent', 'Good'];
                                var pub366BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                var housetype_366 = $('input[name="field_1"]:checked').val();

                                if (ltv_366 <= 0.90 &&
                                    loan_366 >= 100000 &&
                                    housetype_366 != "Mobile Home" &&
                                    tier1_credits_366.indexOf(credit_366) != -1 &&
                                    pub366BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm366 = document.createElement('iframe');
                                    ifrm366.setAttribute('scrolling', 'no');
                                    ifrm366.setAttribute('frameborder', '0');
                                    ifrm366.setAttribute('width', '1');
                                    ifrm366.setAttribute('height', '1');
                                    ifrm366.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=401&e=7&f=js&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm366); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '526') {
                                var ltv_526 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_526 = $('select[name="field_2"]')
                                //     .val();
                                var loan_526 = $('select[name="field_4"]').val();
                                var credit_526 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_526 = ['Excellent', 'Good', 'Fair'];
                                var pub526BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                var housetype_526 = $('input[name="field_1"]:checked').val();

                                if (ltv_526 <= 0.90 &&
                                    loan_526 >= 125000 &&
                                    tier1_credits_526.indexOf(credit_526) != -1 &&
                                    // housetype_526 != "Mobile Home" &&
                                    pub526BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {

                                    var lead_id = results.pixel.ref_01;
                                    var ifrm526 = document.createElement('iframe');
                                    ifrm526.setAttribute('scrolling', 'no');
                                    ifrm526.setAttribute('frameborder', '0');
                                    ifrm526.setAttribute('width', '1');
                                    ifrm526.setAttribute('height', '1');
                                    ifrm526.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=406&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm526); // to place at end of document


                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '897') {
                                var ltv_897 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                               
                                var loan_897 = $('select[name="field_4"]').val();
                                var credit_897 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_897 = ['Excellent', 'Good'];
                                var pub897BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_897 = $('input[name="field_1"]:checked').val();

                                if (ltv_897 <= 1 &&
                                    loan_897 >= 125000 &&
                                    tier1_credits_897.indexOf(credit_897) != -1 &&
                                    pub897BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var glourl = window.location.pathname;
                                    if (glourl == "/v1/") {
                                        var lead_id = results.pixel.ref_01;
                                        var ifrm897 = document.createElement('iframe');
                                        ifrm897.setAttribute('scrolling', 'no');
                                        ifrm897.setAttribute('frameborder', '0');
                                        ifrm897.setAttribute('width', '1');
                                        ifrm897.setAttribute('height', '1');
                                        ifrm897.setAttribute('src', '//trackqualitylps.com/p.ashx?o=390&e=7&t=' + lead_id + '&r=' + cakereqid);
                                        document.body.appendChild(ifrm897); // to place at end of document
                                    } else {
                                        var lead_id = results.pixel.ref_01;
                                        var ifrm897 = document.createElement('iframe');
                                        ifrm897.setAttribute('scrolling', 'no');
                                        ifrm897.setAttribute('frameborder', '0');
                                        ifrm897.setAttribute('width', '1');
                                        ifrm897.setAttribute('height', '1');
                                        ifrm897.setAttribute('src', '//trackqualitylps.com/p.ashx?o=387&e=7&t=' + lead_id + '&r=' + cakereqid);
                                        document.body.appendChild(ifrm897); // to place at end of document
                                    }
                                    
                                }
                            }

                             if ($('input[name="publisher_id"]').val() == '1018') {
                                var ltv_1018 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                
                                var loan_1018 = $('select[name="field_4"]').val();
                                var credit_1018 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1018 = ['Excellent', 'Good'];
                                var pub1018BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1018 = $('input[name="field_1"]:checked').val();

                                if (ltv_1018 <= 0.95 &&
                                    loan_1018 >= 125000 &&
                                    tier1_credits_1018.indexOf(credit_1018) != -1 &&
                                    pub1018BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1018 = document.createElement('iframe');
                                    ifrm1018.setAttribute('scrolling', 'no');
                                    ifrm1018.setAttribute('frameborder', '0');
                                    ifrm1018.setAttribute('width', '1');
                                    ifrm1018.setAttribute('height', '1');
                                    ifrm1018.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=383&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1018); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1040') {
                                var ltv_1040 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1040 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1040 = $('select[name="field_4"]').val();
                                var credit_1040 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1040 = ['Excellent', 'Good'];
                                var pub1040BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                var housetype_1040 = $('input[name="field_1"]:checked').val();

                                if (ltv_1040 <= 0.95 &&
                                    loan_1040 >= 125000 &&
                                    tier1_credits_1040.indexOf(credit_1040) != -1 &&
                                    housetype_1040 != "Mobile Home" &&
                                    pub1040BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {

                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1040 = document.createElement('iframe');
                                    ifrm1040.setAttribute('scrolling', 'no');
                                    ifrm1040.setAttribute('frameborder', '0');
                                    ifrm1040.setAttribute('width', '1');
                                    ifrm1040.setAttribute('height', '1');
                                    ifrm1040.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=405&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1040); // to place at end of document


                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1079') {
                                var ltv_1079 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                               
                                var loan_1079 = $('select[name="field_4"]').val();
                                var credit_1079 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1079 = ['Excellent', 'Good'];
                                var pub1079BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1079 = $('input[name="field_1"]:checked').val();

                                if (ltv_1079 <= 0.9 &&
                                    loan_1079 >= 150000 &&
                                    housetype_1079 != "Mobile Home" &&
                                    tier1_credits_1079.indexOf(credit_1079) != -1 &&
                                    pub1079BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1079 = document.createElement('iframe');
                                    ifrm1079.setAttribute('scrolling', 'no');
                                    ifrm1079.setAttribute('frameborder', '0');
                                    ifrm1079.setAttribute('width', '1');
                                    ifrm1079.setAttribute('height', '1');
                                    ifrm1079.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=398&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1079); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1133') {
                                var ltv_1133 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                              
                                var loan_1133 = $('select[name="field_4"]').val();
                                var credit_1133 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1133 = ['Excellent', 'Good'];
                                var pub1133BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1133 = $('input[name="field_1"]:checked').val();

                                if (ltv_1133 <= 0.95 &&
                                    loan_1133 >= 125000 &&
                                    housetype_1133 != "Mobile Home" &&
                                    tier1_credits_1133.indexOf(credit_1133) != -1 &&
                                    pub1133BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1133 = document.createElement('iframe');
                                    ifrm1133.setAttribute('scrolling', 'no');
                                    ifrm1133.setAttribute('frameborder', '0');
                                    ifrm1133.setAttribute('width', '1');
                                    ifrm1133.setAttribute('height', '1');
                                    ifrm1133.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=382&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1133); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1136') {
                                var ltv_1136 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                              
                                var loan_1136 = $('select[name="field_4"]').val();
                                var credit_1136 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1136 = ['Excellent', 'Good'];
                                var pub1136BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1136 = $('input[name="field_1"]:checked').val();

                                if (ltv_1136 <= 0.95 &&
                                    loan_1136 >= 125000 &&
                                    // housetype_1136 != "Mobile Home" &&
                                    tier1_credits_1136.indexOf(credit_1136) != -1 &&
                                    pub1136BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1136 = document.createElement('iframe');
                                    ifrm1136.setAttribute('scrolling', 'no');
                                    ifrm1136.setAttribute('frameborder', '0');
                                    ifrm1136.setAttribute('width', '1');
                                    ifrm1136.setAttribute('height', '1');
                                    ifrm1136.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=424&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1136); // to place at end of document
                                }
                            }

                          

                            if ($('input[name="publisher_id"]').val() == '1161') {

                                var ltv_1161 = Math.round(($('select[name="field_4"]')
                                         .val() / $('select[name="field_2"]')
                                         .val()) * 100) / 100;                            
                                var house_1161 = $('select[name="field_2"]').val();
                                var loan_1161 = $('select[name="field_4"]').val();
                                var credit_1161 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1161 = ['Excellent', 'Good'];
                                var pub1161BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                var housetype_1161 = $('input[name="field_1"]:checked').val();

                                if (ltv_1161 <= .90 &&
                                    loan_1161 >= 200000 &&
                                    house_1161 >= 100000 &&
                                    housetype_1161 != "Mobile Home" &&
                                    tier1_credits_1161.indexOf(credit_1161) != -1 &&
                                    pub1161BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    var subid = $('input[name="subid"]').val();
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1161 = '<img src="//www.affiliatestrk.com/rd/apx.php?id=215&type=4&hid=' + subid + '&transid=' + lead_id + '" width="1" height="1" style="display:none;" />';
                                    $('body')
                                        .append(pixel_1161);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1162') {
                                var ltv_1162 = Math.round(($('select[name="field_4"]')
                                   .val() / $('select[name="field_2"]')
                                   .val()) * 100) / 100;                            
                                var house_1162 = $('select[name="field_2"]').val();
                                var loan_1162 = $('select[name="field_4"]').val();
                                var credit_1162 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1162 = ['Excellent', 'Good', 'Fair'];
                                var pub1162BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                if (ltv_1162 <= .95 &&
                                    loan_1162 >= 125000 &&
                                    house_1162 >= 100000 &&
                                    tier1_credits_1162.indexOf(credit_1162) != -1 &&
                                    pub1162BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    var subid = $('input[name="subid"]').val();
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1162 = '<img src="//www.affiliatestrk.com/rd/apx.php?id=215&type=4&hid=' + subid + '&transid=' + lead_id + '" width="1" height="1" style="display:none;" />';
                                    $('body')
                                        .append(pixel_1162);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1172') {
                                var ltv_1172 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1172 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1172 = $('select[name="field_4"]').val();
                                var credit_1172 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1172 = ['Excellent', 'Good', 'Fair'];
                                var pub1172BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1172 = $('input[name="field_1"]:checked').val();

                                if (ltv_1172 <= 0.9 &&
                                    loan_1172 >= 125000 &&
                                    // housetype_1172 != "Mobile Home" &&
                                    tier1_credits_1172.indexOf(credit_1172) != -1 &&
                                    pub1172BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1172 = document.createElement('iframe');
                                    ifrm1172.setAttribute('scrolling', 'no');
                                    ifrm1172.setAttribute('frameborder', '0');
                                    ifrm1172.setAttribute('width', '1');
                                    ifrm1172.setAttribute('height', '1');
                                    ifrm1172.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=422&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1172); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1187') {
                                var ltv_1187 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1187 = $('select[name="field_4"]').val();
                                var credit_1187 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1187 = ['Excellent', 'Good'];
                                var pub1187BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1187 <= 0.95 &&
                                    loan_1187 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1187.indexOf(credit_1187) != -1 &&
                                    pub1187BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1187 = document.createElement('iframe');
                                    ifrm1187.setAttribute('scrolling', 'no');
                                    ifrm1187.setAttribute('frameborder', '0');
                                    ifrm1187.setAttribute('width', '1');
                                    ifrm1187.setAttribute('height', '1');
                                    ifrm1187.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=399&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1187); // to place at end of document
                                }
                            }
                            
                            if ($('input[name="publisher_id"]').val() == '1209') {
                                var ltv_1209 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1209 = $('select[name="field_4"]').val();
                                var credit_1209 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1209 = ['Excellent', 'Good'];
                                var pub1209BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                var housetype_1209 = $('input[name="field_1"]:checked').val();

                                if (ltv_1209 <= 0.90 &&
                                    loan_1209 >= 150000 &&
                                    housetype_1209 != "Mobile Home" &&
                                    tier1_credits_1209.indexOf(credit_1209) != -1 &&
                                    pub1209BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1209 = document.createElement('iframe');
                                    ifrm1209.setAttribute('scrolling', 'no');
                                    ifrm1209.setAttribute('frameborder', '0');
                                    ifrm1209.setAttribute('width', '1');
                                    ifrm1209.setAttribute('height', '1');
                                    ifrm1209.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=402&e=7&f=js&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1209); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1254') {
                                var ltv_1254 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1254 = $('select[name="field_4"]').val();
                                var credit_1254 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1254 = ['Excellent', 'Good'];
                                var pub1254BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1254 <= 0.95 &&
                                    loan_1254 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1254.indexOf(credit_1254) != -1 &&
                                    pub1254BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1254 = document.createElement('iframe');
                                    ifrm1254.setAttribute('scrolling', 'no');
                                    ifrm1254.setAttribute('frameborder', '0');
                                    ifrm1254.setAttribute('width', '1');
                                    ifrm1254.setAttribute('height', '1');
                                    ifrm1254.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=376&e=ld&t=' + lead_id);
                                    document.body.appendChild(ifrm1254); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1255') {
                                var ltv_1255 = Math.round(($('select[name="field_4"]')
                                 .val() / $('select[name="field_2"]')
                                 .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1255 = $('select[name="field_4"]').val();
                                var credit_1255 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1255 = ['Excellent', 'Good'];
                                var pub1255BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                // var housetype_1255 = $('input[name="field_1"]').val();

                                var housetype_1255 = $('input[name="field_1"]:checked').val();

                                if (ltv_1255 <= 0.95 &&
                                    loan_1255 >= 100000 &&
                                    housetype_1255 != "Mobile Home" &&
                                    tier1_credits_1255.indexOf(credit_1255) != -1 &&
                                    pub1255BadStates.indexOf($('input[name="state"]')
                                     .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1255 = document.createElement('iframe');
                                    ifrm1255.setAttribute('scrolling', 'no');
                                    ifrm1255.setAttribute('frameborder', '0');
                                    ifrm1255.setAttribute('width', '1');
                                    ifrm1255.setAttribute('height', '1');
                                    ifrm1255.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=377&e=7&t=' + lead_id);
                                    document.body.appendChild(ifrm1255); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1260') {
                                var ltv_1260 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                              
                                var house_1260 = $('select[name="field_2"]').val();
                                var loan_1260 = $('select[name="field_4"]').val();
                                var credit_1260 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1260 = ['Excellent', 'Good'];
                                var pub1260BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1260 = $('input[name="field_1"]:checked').val();

                                if (ltv_1260 <= .95 &&
                                    loan_1260 >= 150000 &&
                                    house_1260 >= 100000 &&
                                    housetype_1260 != "Mobile Home" &&
                                    tier1_credits_1260.indexOf(credit_1260) != -1 &&
                                    pub1260BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1260 = '<img src="https://www.facebook.com/tr?id=1062559747516093&ev=Lead&noscript=1" />';
                                    $('body').append(pixel_1260);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1261') {
                                var ltv_1261 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                               
                                var loan_1261 = $('select[name="field_4"]').val();
                                var credit_1261 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1261 = ['Excellent', 'Good'];
                                var pub1261BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1261 = $('input[name="field_1"]:checked').val();

                                if (ltv_1261 <= .95 &&
                                    loan_1261 >= 125000 &&
                                    tier1_credits_1261.indexOf(credit_1261) != -1 &&
                                    pub1261BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1261 = document.createElement('iframe');
                                    ifrm1261.setAttribute('scrolling', 'no');
                                    ifrm1261.setAttribute('frameborder', '0');
                                    ifrm1261.setAttribute('width', '1');
                                    ifrm1261.setAttribute('height', '1');
                                    ifrm1261.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=385&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1261); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1264') {
                                var ltv_1264 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                
                                var loan_1264 = $('select[name="field_4"]').val();
                                var credit_1264 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1264 = ['Excellent', 'Good'];
                                var pub1264BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                if (ltv_1264 <= .95 &&
                                    loan_1264 >= 100000 &&
                                    tier1_credits_1264.indexOf(credit_1264) != -1 &&
                                    pub1264BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1264 = document.createElement('iframe');
                                    ifrm1264.setAttribute('scrolling', 'no');
                                    ifrm1264.setAttribute('frameborder', '0');
                                    ifrm1264.setAttribute('width', '1');
                                    ifrm1264.setAttribute('height', '1');
                                    ifrm1264.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=391&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1264); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1265') {
                                var ltv_1265 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                var loan_1265 = $('select[name="field_4"]').val();
                                var credit_1265 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1265 = ['Excellent', 'Good'];
                                var pub1265BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1265 = $('input[name="field_1"]:checked').val();

                                if (ltv_1265 <= 1 &&
                                    loan_1265 >= 125000 &&
                                    tier1_credits_1265.indexOf(credit_1265) != -1 &&
                                    pub1265BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    
                                        var lead_id = results.pixel.ref_01;
                                        var ifrm1265 = document.createElement('iframe');
                                        ifrm1265.setAttribute('scrolling', 'no');
                                        ifrm1265.setAttribute('frameborder', '0');
                                        ifrm1265.setAttribute('width', '1');
                                        ifrm1265.setAttribute('height', '1');
                                        ifrm1265.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=394&e=7&t=' + lead_id + '&r=' + cakereqid);
                                        document.body.appendChild(ifrm1265); // to place at end of document
                                    
                                    
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1266') {
                                var ltv_1266 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                               
                                var loan_1266 = $('select[name="field_4"]').val();
                                var credit_1266 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1266 = ['Excellent', 'Good'];
                                var pub1266BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1266 = $('input[name="field_1"]:checked').val();

                                if (ltv_1266 <= .95 &&
                                    loan_1266 >= 150000 &&
                                    tier1_credits_1266.indexOf(credit_1266) != -1 &&
                                    housetype_1266 != "Mobile Home" &&
                                    pub1266BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    
                                        var lead_id = results.pixel.ref_01;
                                        var ifrm1266 = document.createElement('iframe');
                                        ifrm1266.setAttribute('scrolling', 'no');
                                        ifrm1266.setAttribute('frameborder', '0');
                                        ifrm1266.setAttribute('width', '1');
                                        ifrm1266.setAttribute('height', '1');
                                        ifrm1266.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=392&e=7&t=' + lead_id + '&r=' + cakereqid);
                                        document.body.appendChild(ifrm1266); // to place at end of document
                                    
                                    
                                }
                            }
                            
                            if ($('input[name="publisher_id"]').val() == '1268') {
                                var ltv_1268 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1268 = $('select[name="field_4"]').val();
                                var credit_1268 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1268 = ['Excellent', 'Good', 'Fair'];
                                var pub1268BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1268 <= 0.95 &&
                                    loan_1268 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1268.indexOf(credit_1268) != -1 &&
                                    pub1268BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1268 = document.createElement('iframe');
                                    ifrm1268.setAttribute('scrolling', 'no');
                                    ifrm1268.setAttribute('frameborder', '0');
                                    ifrm1268.setAttribute('width', '1');
                                    ifrm1268.setAttribute('height', '1');
                                    ifrm1268.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=400&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1268); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1279') {
                                var ltv_1279 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1279 = $('select[name="field_4"]').val();
                                var credit_1279 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1279 = ['Excellent', 'Good'];
                                var pub1279BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1279 <= 0.9 &&
                                    loan_1279 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1279.indexOf(credit_1279) != -1 &&
                                    pub1279BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1279 = document.createElement('iframe');
                                    ifrm1279.setAttribute('scrolling', 'no');
                                    ifrm1279.setAttribute('frameborder', '0');
                                    ifrm1279.setAttribute('width', '1');
                                    ifrm1279.setAttribute('height', '1');
                                    ifrm1279.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=410&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1279); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1279') {
                                var ltv_1279 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1279 = $('select[name="field_4"]').val();
                                var credit_1279 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1279 = ['Fair'];
                                var pub1279BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1279 <= 0.9 &&
                                    loan_1279 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1279.indexOf(credit_1279) != -1 &&
                                    pub1279BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1279 = document.createElement('iframe');
                                    ifrm1279.setAttribute('scrolling', 'no');
                                    ifrm1279.setAttribute('frameborder', '0');
                                    ifrm1279.setAttribute('width', '1');
                                    ifrm1279.setAttribute('height', '1');
                                    ifrm1279.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=410&e=30&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1279); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1280') {
                                var ltv_1280 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1280 = $('select[name="field_4"]').val();
                                var credit_1280 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1280 = ['Excellent', 'Good', 'Fair'];
                                var pub1280BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1280 <= 0.9 &&
                                    loan_1280 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1280.indexOf(credit_1280) != -1 &&
                                    pub1280BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1280 = document.createElement('iframe');
                                    ifrm1280.setAttribute('scrolling', 'no');
                                    ifrm1280.setAttribute('frameborder', '0');
                                    ifrm1280.setAttribute('width', '1');
                                    ifrm1280.setAttribute('height', '1');
                                    ifrm1280.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=411&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1280); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1281') {
                                var ltv_1281 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1281 = $('select[name="field_4"]').val();
                                var credit_1281 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1281 = ['Excellent', 'Good', 'Fair'];
                                var pub1281BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1281 <= 0.9 &&
                                    loan_1281 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1281.indexOf(credit_1281) != -1 &&
                                    pub1281BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1281 = document.createElement('iframe');
                                    ifrm1281.setAttribute('scrolling', 'no');
                                    ifrm1281.setAttribute('frameborder', '0');
                                    ifrm1281.setAttribute('width', '1');
                                    ifrm1281.setAttribute('height', '1');
                                    ifrm1281.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=412&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1281); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1282') {
                                var ltv_1282 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1282 = $('select[name="field_4"]').val();
                                var credit_1282 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1282 = ['Excellent', 'Good', 'Fair'];
                                var pub1282BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1282 <= 0.9 &&
                                    loan_1282 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1282.indexOf(credit_1282) != -1 &&
                                    pub1282BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1282 = document.createElement('iframe');
                                    ifrm1282.setAttribute('scrolling', 'no');
                                    ifrm1282.setAttribute('frameborder', '0');
                                    ifrm1282.setAttribute('width', '1');
                                    ifrm1282.setAttribute('height', '1');
                                    ifrm1282.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=414&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1282); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1283') {
                                var ltv_1283 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1283 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1283 = $('select[name="field_4"]').val();
                                var credit_1283 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1283 = ['Excellent', 'Good'];
                                var pub1283BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1283 <= 0.95 &&
                                    loan_1283 >= 125000 &&
                                    // housetype_1283 != "Mobile Home" &&
                                    tier1_credits_1283.indexOf(credit_1283) != -1 &&
                                    pub1283BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1283 = document.createElement('iframe');
                                    ifrm1283.setAttribute('scrolling', 'no');
                                    ifrm1283.setAttribute('frameborder', '0');
                                    ifrm1283.setAttribute('width', '1');
                                    ifrm1283.setAttribute('height', '1');
                                    ifrm1283.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=416&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1283); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1284') {
                                var ltv_1284 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1284 = $('select[name="field_2"]').val();
                                var loan_1284 = $('select[name="field_4"]').val();
                                var credit_1284 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1284 = ['Excellent', 'Good'];
                                var pub1284BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1284 = $('input[name="field_1"]:checked').val();

                                if (ltv_1284 <= .95 &&
                                    loan_1284 >= 125000 &&
                                    // house_1284 >= 100000 &&
                                    // housetype_1284 != "Mobile Home" &&
                                    tier1_credits_1284.indexOf(credit_1284) != -1 &&
                                    pub1284BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1284 = '<img src="https://www.facebook.com/tr?id=445384370081184&ev=PageView&noscript=1" />';
                                    $('body').append(pixel_1284);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1291') {
                                var ltv_1291 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1291 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1291 = $('select[name="field_4"]').val();
                                var credit_1291 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1291 = ['Excellent', 'Good', 'Fair'];
                                var pub1291BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1291 = $('input[name="field_1"]:checked').val();

                                if (ltv_1291 <= 0.9 &&
                                    loan_1291 >= 125000 &&
                                    // housetype_1291 != "Mobile Home" &&
                                    tier1_credits_1291.indexOf(credit_1291) != -1 &&
                                    pub1291BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1291 = document.createElement('iframe');
                                    ifrm1291.setAttribute('scrolling', 'no');
                                    ifrm1291.setAttribute('frameborder', '0');
                                    ifrm1291.setAttribute('width', '1');
                                    ifrm1291.setAttribute('height', '1');
                                    ifrm1291.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=419&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1291); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1293') {
                                var ltv_1293 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1293 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1293 = $('select[name="field_4"]').val();
                                var credit_1293 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1293 = ['Excellent', 'Good', 'Fair'];
                                var pub1293BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1293 = $('input[name="field_1"]:checked').val();

                                if (ltv_1293 <= 0.9 &&
                                    loan_1293 >= 125000 &&
                                    // housetype_1293 != "Mobile Home" &&
                                    tier1_credits_1293.indexOf(credit_1293) != -1 &&
                                    pub1293BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1293 = document.createElement('iframe');
                                    ifrm1293.setAttribute('scrolling', 'no');
                                    ifrm1293.setAttribute('frameborder', '0');
                                    ifrm1293.setAttribute('width', '1');
                                    ifrm1293.setAttribute('height', '1');
                                    ifrm1293.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=423&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1293); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1296') {
                                var ltv_1296 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1296 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1296 = $('select[name="field_4"]').val();
                                var credit_1296 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1296 = ['Excellent', 'Good'];
                                var pub1296BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1296 = $('input[name="field_1"]:checked').val();

                                if (ltv_1296 <= 0.95 &&
                                    loan_1296 >= 125000 &&
                                    // housetype_1296 != "Mobile Home" &&
                                    tier1_credits_1296.indexOf(credit_1296) != -1 &&
                                    pub1296BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1296 = document.createElement('iframe');
                                    ifrm1296.setAttribute('scrolling', 'no');
                                    ifrm1296.setAttribute('frameborder', '0');
                                    ifrm1296.setAttribute('width', '1');
                                    ifrm1296.setAttribute('height', '1');
                                    ifrm1296.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=427&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1296); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1304') {
                                var ltv_1304 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1304 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1304 = $('select[name="field_4"]').val();
                                var credit_1304 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1304 = ['Excellent', 'Good', 'Fair'];
                                var pub1304BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1304 = $('input[name="field_1"]:checked').val();

                                if (ltv_1304 <= 0.90 &&
                                    loan_1304 >= 125000 &&
                                    // housetype_1304 != "Mobile Home" &&
                                    tier1_credits_1304.indexOf(credit_1304) != -1 &&
                                    pub1304BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1304 = document.createElement('iframe');
                                    ifrm1304.setAttribute('scrolling', 'no');
                                    ifrm1304.setAttribute('frameborder', '0');
                                    ifrm1304.setAttribute('width', '1');
                                    ifrm1304.setAttribute('height', '1');
                                    ifrm1304.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=434&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1304); // to place at end of document
                                }
                            }

                        }
                    }
                });
                
                getClickWall();
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();
                $('.step-form-wrap.data-step')
                    .removeClass('data-step');

            }
        } else {
            switch (step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
            case 7:
                step_7.validate();
                break;
            case 8:
                step_8.validate();
                break;
            case 9:
                step_9.validate();
                break;
            case 10:
                step_10.validate();
                break;
            }
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                changeStep(step);
            }
        }
    });

// Tooltip
$(document)
    .on('click', 'a.tooltip', function (e) {
        e.preventDefault();
    });

    // Use for the GeoIP2 JavaScript API is to fill state name
    var fillState = (function () {
        var getStateName = function (geoipResponse) {
            /*
                most_specific_subdivision returns the smallest available
                subdivision (region) as defined in ISO 3166-2.
            */
            var regionName = geoipResponse.most_specific_subdivision.names.en || '';
            if (regionName) {
                document.getElementById("header-state-text")
                    .innerHTML = regionName + ' ';
            }
            };
            var onSuccess = function (geoipResponse) {
                getStateName(geoipResponse);
            };
            /* If we get an error we will do nothing*/
            var onError = function (error) {
                return;
            };
            return function () {
            geoip2.city(onSuccess, onError);
            };
        }());

//Fill current date
var dt = new Date();
var month = dt.toDateString()
    .split(' ')[1];
var day = dt.toDateString()
    .split(' ')[2];
var year = dt.toDateString()
    .split(' ')[3];
var currentDate = month + ' ' + day + ', ' + year;
document.getElementById("date")
    .innerHTML = currentDate;

var allowedHashes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];



$(document)
    .ready(function () {

        /*
         * Generate a TrustedForm Certificate
         */
        $('input')
            .on('input', function () {
                this.setAttribute('value', $(this)
                    .val());
            });
        (function () {
            var field = 'TrustedFormToken';
            var provideReferrer = false;
            var tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                '://api.trustedform.com/trustedform.js?provide_referrer=' +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date()
                .getTime() +
                Math.random();
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tf, s);
        })();
        /*
              LEAD ID
           */


        $('input[name=zip_code]')
            .val("");
        $(window)
            .keydown(function (e) {
                if ($('input[name=zip_code]')
                    .index($(e.target)) != -1) {
                    if (
                        // digits, digits in num pad, 'back', 'enter', '.', '-'
                        ($.inArray(e.keyCode, [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 13, 190, 189]) == -1) ||
                        // not allow double dot '.'
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .indexOf(".") != -1) ||
                        // not allow dot '.' at the begining    
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .length == 0)
                    ) {
                        e.preventDefault();
                    } else {
                        if ($(e.target)
                            .val()
                            .length > 4 && e.keyCode !== 8) {
                            e.preventDefault();
                        }
                    }
                }
            });

        var hashValue = location.hash;
        hashValue = hashValue.replace(/^#/, '');

        if (isInArray(hashValue, allowedHashes)) {
            if (ls('zip')) {
                getCity(ls('zip'));
                setTimeout(function () {
                    showPopUp(false);
                    changeStep(hashValue);
                }, 200);
            }
            setTimeout(function () {
                $('html')
                    .addClass('ready');
            }, 300);
        } else {
            $('html')
                .addClass('ready');
        }

        fillState();
        
        if (typeof params.zip !== 'undefined') {
            $("input[name='zip_code']")
                .val(params.zip);
            $('.start-form-submit')
                .trigger('click');
        }
        if (
            typeof params.show_confirmation !== 'undefined' &&
            (
                params.show_confirmation === 'true' ||
                params.show_confirmation === '1'
            )
        ) {
            showPopUp();
            $('.popup')
                .removeClass('hide');
            $('.step.active')
                .removeClass('active');
            $('.step.step-confirmation')
                .addClass('active');
            $('.form-top .steps')
                .empty();
            var exit_strategy = true;

            getClickWall(exit_strategy);
            $('.step-form-wrap.data-step')
                .removeClass('data-step');

        } else if (
            typeof params.optout !== 'undefined' &&
            (
                params.optout === 'true' ||
                params.optout === '1'
            )
        ) {
            $('#opt-out-link')
                .click();
        } else if (
            typeof params.privacy !== 'undefined' &&
            (
                params.privacy === 'true' ||
                params.privacy === '1'
            )
        ) {
            $('#privacy-link')
                .click();
        } else if (
            typeof params.terms !== 'undefined' &&
            (
                params.terms === 'true' ||
                params.terms === '1'
            )
        ) {
            $('#terms-link')
                .click();
        }
    });